export const stories = [
  { image: "assets/images/results/result-1.jpg" },
  { image: "assets/images/results/result-2.jpg" },
  { image: "assets/images/results/result-3.jpg" },
  { image: "assets/images/results/result-4.jpg" },
  { image: "assets/images/results/result-5.jpg" },
  { image: "assets/images/results/result-6.jpg" },
  { image: "assets/images/results/result-7.jpg" },
  { image: "assets/images/results/result-8.jpg" },
  { image: "assets/images/results/result-9.jpg" },
  { image: "assets/images/results/result-10.jpg" },
  { image: "assets/images/results/result-11.jpg" },
  { image: "assets/images/results/result-12.jpg" },
  { image: "assets/images/results/result-13.jpg" },
];
